import React from 'react';


import {Routes, Route} from 'react-router-dom';



import Home from './Home';
import UhsllRpi from './UhsllRpi';
import UhsllScores from './UhsllScores';


class App extends React.Component {

  render() {

    return (
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/rpi" element={<UhsllRpi/>}/>
        <Route path="/scores" element={<UhsllScores/>}/>

        <Route element={<Home/>}/>
      </Routes>
    );
  }
}


export default App;




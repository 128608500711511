import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router } from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';


import './styles/index.css';
import App from './components/App';

import * as serviceWorker from './serviceWorker';


const theme = createTheme({
  palette: {
    primary: {
      main: '#ff0000'
    },
    secondary: {
      main: '#031d44'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  //<React.StrictMode> // this causes componentDidMount() to get called twice on each page
    <Router>
      <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>
    </Router>
  //</React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//registerServiceWorker();
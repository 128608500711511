import React from 'react';

import { RingLoader } from 'react-spinners';
import moment from 'moment';

import Colors from "../Colors";
//import Fonts from "../Fonts";

import '../styles/Uhsll.css';


const seasonId = 22083;  // 2018 = 20506, 2019 = 22780, 2023 men's league = 22083
const assocId = 24598; // 7434 is old UHSLL, 24598 is IMLAX men's league
//const org = 'utahlax.org';
const division = 27346;
const mainUrl = 'https://api.leagueathletics.com/API/schedules?division=';
const dtStart = '06-01-2023';
const dtEnd = '09-01-2023';

const scoresUrl = mainUrl + division + '&season=' + seasonId + '&assocId=' + assocId + '&dtStart=' + dtStart + '&dtEnd=' + dtEnd;

let scrollToID = 0;


class UhsllScores extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      isLoading: true,
      error: false,

      sortedList: [],
    };
  }


  componentDidMount() {
    this.getScores();
  }


  getScores = () => {

    fetch(scoresUrl)
      .then(response => response.json())
      .then(data => {

        //console.log(scoresUrl);
        let originalScores = [];

        let events = data.Events;

        for (let key in events) {

          //console.log('key = ' + JSON.stringify(events[key], null, 2));

          //if (events[key].Type === 'Varsity' && events[key].Cancelled === false && events[key].isGame === true) {
          if (events[key].Type === 'Game' && events[key].Cancelled === false && events[key].isGame === true) {

            let tmpObj = {};

            let homeName = events[key].Home.Name.replace(" BHS", "");
            let awayName = events[key].Away.Name.replace(" BHS", "");
            let homeScore = events[key].Home.Score;
            let awayScore = events[key].Away.Score;
            let gameDate = moment(events[key].StartDate).format("MMM DD");
            let gameTime = moment(events[key].StartDate).format("h:mm a");

            let index = key;

            if (scrollToID === 0) { // so it's at least set to bottom of page when there's no scores
              scrollToID = key;
            }


            if (homeScore === null) {
              homeScore = '-';
            } else {
              scrollToID = key;
            }

            if (awayScore === null) {
              awayScore = '-';
            }

            tmpObj.homeName = homeName;
            tmpObj.homeScore = homeScore;
            tmpObj.awayName = awayName;
            tmpObj.awayScore = awayScore;
            tmpObj.date = gameDate;
            tmpObj.time = gameTime;
            tmpObj.index = index;

            //console.log(gameDate + ' ' + awayName + ' ' + awayScore + ' @ ' + homeName + ' ' + homeScore);
            originalScores.push(tmpObj);
          }

        }

        let reversedArray = originalScores.reverse();

        this.setState({ sortedList: reversedArray, isLoading: false },
          () => {
            //console.log('last state = ' + scrollToID);
            document.getElementById(scrollToID).scrollIntoView();
          }
        )

      }).catch(error => {
      this.setState({error: true});
      console.log('error 1 = ' + error);
    });
  };




  render() {


    if (this.state.error) {
      return (
        <div className="uhsllSpinner">
          {this.state.error}
        </div>
      );
    }


    if (this.state.isLoading) {
      return (
        <div className="uhsllSpinner">
          <RingLoader
            color={Colors.blue}
            loading={true}
          />
        </div>
      );
    }




    function ListItem (props) {

      const data = props.value;

      return (
        <div className="scoresWrapper" id={data.index}>

          <div className="col3">
            <div className="teamName">{data.date}</div>
          </div>

          <div className="colTeam">
            <div className="teamName">{data.awayName}</div>
          </div>

          <div className="col1">
            <div className="teamName">{data.awayScore}</div>
          </div>

          <div className="col1">
            <div className="teamName">@</div>
          </div>

          <div className="colTeam">
            <div className="teamName">{data.homeName}</div>
          </div>

          <div className="col1">
            <div className="teamName">{data.homeScore}</div>
          </div>

          {(data.homeScore === '-') &&
            <div className="colTeam">
              <div className="teamName">{data.time}</div>
            </div>
          }

        </div>
      );
    }


    // render a this.state item otherwise it's in the initial empty state
    const listItems = this.state.sortedList.map((item, index) =>
      <ListItem key={index} value={item} />
    );


    return (
      <div className="masterUhsll">
        {listItems}
      </div>
    )

  }

}


export default UhsllScores;

import React from 'react';

import { RingLoader } from 'react-spinners';

import Colors from "../Colors";
//import Fonts from "../Fonts";

import '../styles/Uhsll.css';

const seasonId = 22083;  // 2018 = 20506, 2019 = 22780

// const assocId = 7434;
const org = 'IMLaxUtah';

/*
const homeWin = 0.9;
const awayWin = 1.1;
const neutralWin = 1.0;
*/

// summer league plays on the same field
const homeWin = 1.0;
const awayWin = 1.0;
const neutralWin = 1.0;

const blowoutMargin = 5;
const blowoutBonus = .1;

const ownWeight = .375;
const opponentWeight = .375;
const opponentOpponentWeight = .25;

const teamsUrl = 'https://api.leagueathletics.com/API/Divisions?season=' + seasonId + '&org=' + org;
const resultsUrl = 'https://api.leagueathletics.com/API/results?org=' + org + '&TeamID=';

let inStateTeams = [];
let teamData = [];
let flatArray = [];


class UhsllRpi extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      teamName: '', // just for display on spinner

      error: false,

      sortedList: [],

      haveTeams: false,
      haveRecords: false,
      haveOpponentRecords: false,
      haveOpponentOpponentRecords: false,
      haveOutput: false,

      openRow: {},
    };
  }


  async componentDidMount() {
    await this.getTeams();
    await this.getRecords();
    await this.getOpponentRecords();
    await this.getOpponentOpponentRecords();
    await this.getTotalScore();
    await this.sortScores();
  }




  getTeams = async() => {

    //console.log('called GetTeams');

    await fetch(teamsUrl)
      .then(response => response.json())

      .then(data => {

        let teams = data[0].SubDivisions[1].Teams;


        for(let team of teams) {

          inStateTeams.push(team.Name);

          //console.log(team);

          let tempID = team.Name;

          teamData[tempID] = {}; // set the obj parent key
          teamData[tempID].id = team.ID;
          teamData[tempID].wins = 0;
          teamData[tempID].losses = 0;
          teamData[tempID].pointsOwn = 0;
          teamData[tempID].pointsOpponents = 0;
          teamData[tempID].pointsOpponentsOpponents = 0;

          teamData[tempID].displayResults = [];

          //console.log(team.ID);
          //console.log(team.Name);
        }

        this.setState({ haveTeams: true });

        return true;
      })

      .catch(error => this.setState({ error: 'teams: ' + error }));
  };



  getRecords = async() => {

    //console.log('called GetRecords');

    let url = '';

    for (let key in teamData) {

      this.setState({
        teamName: key,

        openRow: Object.assign({}, this.state.openRow, {
          [key]: false,
        }),

      }); // just for display purposes

      url = resultsUrl + teamData[key].id;

      //console.log('key = ' + key);
      //console.log('url = ' + url);

      teamData[key].results = [];

      await fetch(url)
        .then(response => response.json())

        .then(data => {
          let results = data.results.games;

          let wins = 0;
          let losses = 0;

          //teamData[key].results = [];

          for(let result of results) {

            //console.log('result = ' + JSON.stringify(result, null, 2));

            let notes = result.commentary.toLowerCase();
            let site = notes.replace(/[\W]+/g, '');

            site = /neutral/.test(site); // returns boolean


            if (result.type === 'Game' && result.cancelled === false) {

              if (result.away.id === teamData[key].id) {

                if (inStateTeams.includes(result.home.name)) {

                  if (result.away.score > result.home.score) {

                    if (site === true) {

                      wins += neutralWin;

                      if ((result.away.score - result.home.score) >= blowoutMargin) {
                        wins += blowoutBonus;
                        teamData[key].displayResults.unshift('N ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', +' + Number(neutralWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('N ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', +' + neutralWin);
                      }

                    } else {

                      wins += awayWin;

                      if ((result.away.score - result.home.score) >= blowoutMargin) {
                        wins += blowoutBonus;
                        teamData[key].displayResults.unshift('@ ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', +' + Number(awayWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('@ ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', +' + awayWin);
                      }
                    }



                    teamData[key].results.push(result);

                  } else if (result.away.score < result.home.score) {

                    if (site === true) {

                      losses += neutralWin;

                      if ((result.home.score - result.away.score) >= blowoutMargin) {
                        losses += blowoutBonus;
                        teamData[key].displayResults.unshift('N ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', -' + Number(neutralWin + blowoutBonus).toFixed(1));

                      } else {

                        teamData[key].displayResults.unshift('N ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', -' + neutralWin);
                      }

                    } else {

                      losses += homeWin;

                      if ((result.home.score - result.away.score) >= blowoutMargin) {
                        losses += blowoutBonus;
                        teamData[key].displayResults.unshift('@ ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', -' + Number(homeWin + blowoutBonus).toFixed(1));

                      } else {

                        teamData[key].displayResults.unshift('@ ' + result.home.name.replace(" BHS", "") + ' ' + result.away.score + '-' + result.home.score + ', -' + homeWin);
                      }


                    }

                    teamData[key].results.push(result);
                  }

                }

              } else if (result.home.id === teamData[key].id) {

                if (inStateTeams.includes(result.away.name)) {

                  if (result.home.score > result.away.score) {

                    if (site === true) {

                      wins += neutralWin;

                      if ((result.home.score - result.away.score) >= blowoutMargin) {
                        wins += blowoutBonus;
                        teamData[key].displayResults.unshift('N ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', +' + Number(neutralWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('N ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', +' + neutralWin);
                      }

                    } else {

                      wins += homeWin;

                      if ((result.home.score - result.away.score) >= blowoutMargin) {
                        wins += blowoutBonus;
                        teamData[key].displayResults.unshift('vs ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', +' + Number(homeWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('vs ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', +' + homeWin);
                      }

                    }

                    teamData[key].results.push(result);

                  } else if (result.home.score < result.away.score) {

                    if (site === true) {

                      losses += neutralWin;

                      if ((result.away.score - result.home.score) >= blowoutMargin) {
                        losses += blowoutBonus;
                        teamData[key].displayResults.unshift('N ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', -' + Number(neutralWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('N ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', -' + neutralWin);
                      }

                    } else {

                      losses += awayWin;

                      if ((result.away.score - result.home.score) >= blowoutMargin) {
                        losses += blowoutBonus;
                        teamData[key].displayResults.unshift('vs ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', -' + Number(awayWin + blowoutBonus).toFixed(1));
                      } else {
                        teamData[key].displayResults.unshift('vs ' + result.away.name.replace(" BHS", "") + ' ' + result.home.score + '-' + result.away.score + ', -' + awayWin);
                      }


                    }

                    teamData[key].results.push(result);
                  }

                }

              }

            }

          }


          let winPer = 0;

          if ((wins + losses) > 0) {
            winPer = wins / (wins + losses);
            winPer = Number(winPer).toFixed(3);
          }

          //wins = Number(wins).toFixed(1);
          //losses = Number(losses).toFixed(1);
          //console.log(key + ' was ' + wins + '-' + losses + ' (' + winPer + ')');

          teamData[key].pointsOwn = winPer;
          teamData[key].wins = Number(wins).toFixed(1);
          teamData[key].losses = Number(losses).toFixed(1);

        })

        .catch(error => this.setState({ error: key + ' error: ' + error }));
    }


    this.setState({ haveRecords: true });
    return true;
  };



  getOpponentRecords = async() => {

    for (let key in teamData) {

      let counter = 0;
      let opponentPoints = 0;

      //console.log('team = ' + key);

      for (let result of teamData[key].results) {

        if (result.away.id === teamData[key].id) {

          if (inStateTeams.includes(result.home.name)) {
            opponentPoints = opponentPoints + Number(teamData[result.home.name].pointsOwn);
            //console.log('played ' + result.home.name + ' who was ' + teamData[result.home.name].pointsOwn);
            counter++;
          }

        } else if (result.home.id === teamData[key].id){

          if (inStateTeams.includes(result.away.name)) {
            opponentPoints = opponentPoints + Number(teamData[result.away.name].pointsOwn);
            //console.log('played ' + result.away.name + ' who was ' + teamData[result.away.name].pointsOwn);
            counter++;
          }

        }

      }


      let winPer = 0;

      //console.log('counter = ' + counter);
      //console.log('opponent points = ' + opponentPoints);

      if (counter > 0) {
        winPer = opponentPoints / counter;
        winPer = Number(winPer).toFixed(3);
      }

      //console.log('opponents tally = ' + winPer);

      teamData[key].pointsOpponents = winPer;
    }


    this.setState({ haveOpponentRecords: true });
    return true;
  };




  getOpponentOpponentRecords = async() => {

    for (let key in teamData) {

      let counter = 0;
      let opponentPoints = 0;

      //console.log('team = ' + key);

      for (let result of teamData[key].results) {

        if (result.away.id === teamData[key].id) {

          if (inStateTeams.includes(result.home.name)) {
            opponentPoints = opponentPoints + Number(teamData[result.home.name].pointsOpponents);
            //console.log('opponent ' + result.home.name + ' had oppo win % of ' + teamData[result.home.name].pointsOpponents);
            counter++;
          }

        } else if (result.home.id === teamData[key].id){

          if (inStateTeams.includes(result.away.name)) {
            opponentPoints = opponentPoints + Number(teamData[result.away.name].pointsOpponents);
            //console.log('opponent ' + result.away.name + ' had oppo win % of ' + teamData[result.away.name].pointsOpponents);
            counter++;
          }

        }

      }


      let winPer = 0;

      //console.log('counter = ' + counter);
      //console.log('opponent opponents points = ' + opponentPoints);

      if (counter > 0) {
        winPer = opponentPoints / counter;
        winPer = Number(winPer).toFixed(3);
      }

      //console.log('opponents opponents tally = ' + winPer);

      teamData[key].pointsOpponentsOpponents = winPer;

    }

    this.setState({ haveOpponentOpponentRecords: true });
    return true;
  };




  sortScores = async() => {

    flatArray.sort(function(a, b) {
      return b.total - a.total;
    });


    this.setState({
      sortedList: flatArray,
      haveOutput: true
    });

    //console.log(flatArray);
    return true;
  };



  getTotalScore = async () => {

    for (let key in teamData) {

      let pointsOwn = Number(teamData[key].pointsOwn * ownWeight).toFixed(3);
      let pointsOpponents = Number(teamData[key].pointsOpponents * opponentWeight).toFixed(3);
      let pointsOpponentsOpponents = Number(teamData[key].pointsOpponentsOpponents * opponentOpponentWeight).toFixed(3);

      teamData[key].total = Number(pointsOwn) + Number(pointsOpponents) + Number(pointsOpponentsOpponents);
      //console.log( key + ' was ' + pointsOwn + ', ' + pointsOpponents + ', ' + pointsOpponentsOpponents + ' for total of ' + Number(teamData[key].total).toFixed(3));

      let tempObj = {
        'name': key,
        'wins': teamData[key].wins,
        'losses': teamData[key].losses,
        'pointsOwn': teamData[key].pointsOwn,
        'pointsOpponents': teamData[key].pointsOpponents,
        'pointsOpponentsOpponents': teamData[key].pointsOpponentsOpponents,
        'total': Number(teamData[key].total).toFixed(3),
        'results': teamData[key].displayResults
      };

      flatArray.push(tempObj);
    }

    return true;
  };



  toggleRow = (teamID) => {

    this.setState(prevState => ({
      openRow: {
        ...prevState.openRow,
        [teamID]: !this.state.openRow[teamID]
      }
    }))

  };


  render() {

/*

was a problem with weber's schedule

    if (this.state.error) {
      return (
        <div className="uhsllSpinner">
          {this.state.error}
        </div>
      );
    }
*/


    if (this.state.haveTeams === false) {
      return (
        <div className="uhsllSpinner">
          <RingLoader
            color={Colors.blue}
            loading={true}
          />
          <div className="teamName">Fetching teams</div>
        </div>
      );
    }



    if (this.state.haveRecords === false) {
      return (
        <div className="uhsllSpinner">
          <RingLoader
            color={Colors.blue}
            loading={true}
          />
          <div className="teamName">{this.state.teamName}</div>
        </div>
      );
    }



    if (this.state.haveOpponentRecords === false) {
      return (
        <div className="uhsllSpinner">
          <RingLoader
            color={Colors.blue}
            loading={true}
          />
          <div className="teamName">Calculating Opponents Records</div>
        </div>
      );
    }



    if (this.state.haveOpponentOpponentRecords === false) {
      return (
        <div className="uhsllSpinner">
          <RingLoader
            color={Colors.blue}
            loading={true}
          />
          <div className="teamName">Calculating Opponents' Opponents' Records</div>
        </div>
      );
    }


    function ScheduleSingleItem (props) {
      const lineItem = props.lineItem;
      return(<div>{lineItem}</div>)
    }


    function ScheduleItem (props) {

      const schedule = props.schedule;

      return(
        <div className="teamName">
          { schedule.map((item, index) => <ScheduleSingleItem key={index} lineItem={item} />) }
        </div>
      )
    }


    function ListItem (props) {

      const team = props.value;
      const rank = props.rank + 1;
      const name = team.name;

      const that = props.that;

      //console.log('total = ' + JSON.stringify(team, null, 2));

      return (
        <div className="lineWrapper">

          <div className="firstRow">


            <div className="col1">
              <div className="teamName">{rank}</div>
            </div>

            <div className="col2">
              <div className="teamNameClickable" onClick={ () => that.toggleRow(name) } >
                {team.name.replace(" BHS", "")} ({team.wins} - {team.losses})
              </div>
            </div>

            <div className="col3">
              <div className="teamName">{team.total}</div>
            </div>

            <div className="col4">
              <div className="teamName">{team.pointsOwn}</div>
            </div>

            <div className="col5">
              <div className="teamName">{team.pointsOpponents}</div>
            </div>

            <div className="col6">
              <div className="teamName">{team.pointsOpponentsOpponents}</div>
            </div>

          </div>

          {that.state.openRow[name] === true &&
            <div className="secondRow">
              <ScheduleItem schedule={team.results}/>
            </div>
          }


        </div>
      );
    }


    // render a this.state item otherwise it's in the initial empty state
    let listItems = this.state.sortedList.map((item, index) =>
      <ListItem key={item.name} value={item} rank={index} that={this}/>
    );



    return (
      <div className="masterUhsll">

        {/*
        <div className="explanationWrapper">
          <div className="teamName"><br/><br/> error = {this.state.error.toString()}</div>
        </div>
        */}

        <div className="lineWrapper">
          <div className="firstRow">

            <div className="col1">
              <div className="teamName">rank</div>
            </div>

            <div className="col2">
              <div className="teamName">name</div>
            </div>

            <div className="col3">
              <div className="teamName">total</div>
            </div>

            <div className="col4">
              <div className="teamName">W % {ownWeight}</div>
            </div>

            <div className="col5">
              <div className="teamName">O % {opponentWeight}</div>
            </div>

            <div className="col6">
              <div className="teamName">OO % {opponentOpponentWeight}</div>
            </div>

          </div>
        </div>


        {listItems}


        {/*
        <div className="explanationWrapper">
          <div className="teamName">&bull; There are three components: your win %, your opponent's win %, and your
            opponent's opponents' win %. Those values have coefficients of .375, .375, .250 applied to them to calculate the total score.
          </div>
        </div>

        <div className="explanationWrapper">
          <div className="teamName">&bull; The base value for both a win and a loss is 1.0, with a home/away weighting
            of 0.1. If you win an away game your wins increase by 1.1, if you win a home game your wins increase by 0.9.
            If you lose a home game your losses increase by 1.1, and if you lose an away game your losses increase by
            0.9.
          </div>
        </div>

        <div className="explanationWrapper">
          <div className="teamName">&bull; The opponents win % is based on the average of the percentages, not the sum
            of all the wins and losses of your opponents. For example, if you only had two opponents and one was 8-0 while the other was 0-16
            then your opponent's % would be 0.500 ((1.0 + 0.0) / 2) and not 0.333 (8 / (8 + 16)).
          </div>
        </div>

        <div className="explanationWrapper">
          <div className="teamName">&bull; There is a bonus of 0.1 applied to wins (and as a penalty to losses) if the
            margin of victory exceeds four goals. For example, if you win an away game by 5+ then your wins increase by 1.2, and if you lose an away game by
            5+ then your losses increase by 1.0 (0.9 + 0.1). The bonus is only applied once so beating a team by 5 is the same as beating
            them by 50.
          </div>
        </div>
        */}

      </div>
    )



  }

}


export default UhsllRpi;

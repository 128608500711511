import React from 'react';

import logo from '../images/imlax.png';
import '../styles/Home.css';

class Home extends React.Component {

  render() {

    return (
      <div className="homeMaster">
        <header className="home-header">

          <img src={logo} className="home-logo" alt="logo" />

            <div className="links-row">
              <div className="links-text"><a href="/rpi"> RPI </a></div>
              <div className="links-text">&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
              <div className="links-text"><a href="/scores"> Scores </a></div>
            </div>
        </header>
      </div>
    );

  }

}


export default Home;
